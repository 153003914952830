import {
  FdrAddItemToActiveWishlistMutation,
  FdrAddItemToActiveWishlistMutationVariables,
  useFdrAddItemToActiveWishlistMutation,
} from 'hp-mf/fdr/schemas/mutation/wishlist/fdr-add-item-to-active-wishlist.generated';
import { refetchQueries } from './fdr-wishlist.constants';

const useAddMultipleWishlistOffers = () => {
  function onCompleted(data: FdrAddItemToActiveWishlistMutation) {
    const result = data.fdrAddWishlistItemToActive;

    if (!result.success) {
      // eslint-disable-next-line no-console
      console.error(result.message);
      return;
    }
  }

  const [addMultiple, { loading }] = useFdrAddItemToActiveWishlistMutation({
    refetchQueries,
    onCompleted,
  });

  const onAddMultiple = async (
    items: FdrAddItemToActiveWishlistMutationVariables['input']['items']
  ) => {
    await addMultiple({ variables: { input: { items: items } } });
  };

  return { onAddMultiple, loading };
};

export default useAddMultipleWishlistOffers;
