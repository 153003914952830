import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import {
  NotificationModal,
  NotificationType,
} from '@hotelplan/components.common.modals';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import WishlistForm from 'components/domain/wishlist/WishlistForm';
import { useFdrCreateWishlistMutation } from 'hp-mf/fdr/schemas/mutation/wishlist/fdr-create-wishlist.generated';

const AddWishlistWrapper = styled.div(
  sx2CssThemeFn({
    px: 0,
    pb: [4, '65px'],
    '.failed-add-new-wishlist-text': {
      fontSize: [null, 3],
    },
  })
);

interface IWishlistAddFormContainer {
  toggleBtnText?: string;
  onSubmit?: () => void;
}

const WishlistAddFormContainer: React.FC<IWishlistAddFormContainer> = ({
  toggleBtnText,
  onSubmit,
}) => {
  const [t] = useTranslation(['wishlist']);
  const [isErrorOpened, openError, closeError] = useToggleState(false);

  const [createWishlist] = useFdrCreateWishlistMutation({
    refetchQueries: [`FdrWishlistOverview`, `FdrGetActiveWishlist`],
    onError: openError,
    onCompleted(data) {
      const { success, message } = data.fdrCreateWishlist;

      if (!success) {
        openError();
        // eslint-disable-next-line no-console
        console.error(message);
      }
    },
  });

  const onHandleSubmit = async (name: string) => {
    await createWishlist({
      variables: {
        name,
      },
    });
    onSubmit && onSubmit();
  };

  return (
    <AddWishlistWrapper>
      <WishlistForm
        toggleBtnText={toggleBtnText}
        submitBtnText={t('wishlist:addWishlist.submit.button')}
        onSubmit={onHandleSubmit}
      />
      <NotificationModal
        key="failure-modal"
        name="agencyContactFailure"
        show={isErrorOpened}
        title={t('failedAddNewWishlist.title')}
        type={NotificationType.ERROR}
        onClose={closeError}
      >
        <p className="failed-add-new-wishlist-text">
          {t('failedAddNewWishlist.text')}
        </p>
      </NotificationModal>
    </AddWishlistWrapper>
  );
};

export default WishlistAddFormContainer;
